import React, { useContext } from "react"
import styles from "./ProductTotalsBarTile.module.css"
import baseStyles from "../Tile.module.css"
import { CountSummary } from "../Model/types"
import { EchartsReactWrapper } from "../../Components/EchartReactWrapper"
import { generateGradientColors } from "../utils"
import { ProductColorsContext } from "../SimpleDashboard"
import { EChartOption } from "echarts"

export interface ProductTotalsBarTileProps {
    size?: { width: number; height: number }
    countSummary: CountSummary
}

const ProductTotalsBarTile: React.FC<ProductTotalsBarTileProps> = ({ size = { width: 1000, height: 1000 }, countSummary }) => {
    const layoutStyle = size.width > 300 ? styles.largeLayout : styles.smallLayout

    const productColors = useContext(ProductColorsContext)
    const options = getOptions(countSummary, productColors)

    return (
        <div className={styles.productTotalsBarTile}>
            <div className={baseStyles.title}>Connected Devices by Product</div>
            <div style={{ flexGrow: 1 }}>
                <div className={styles.chart}>
                    <EchartsReactWrapper option={options as any} />
                </div>
            </div>
        </div>
    )
}

function getOptions(countSummary: CountSummary, colors: { [id: string]: string }): any {
    // Mapping through the products to create the series data
    const data = countSummary.products
        .map((product, index) => ({
            name: product.name,
            value: product.latestCount?.connectedCount,
            itemStyle: {
                color: colors[product.key],
            },
        }))
        .sort((a, b) => (a.value ?? 0) - (b.value ?? 0))

    // Extracting the names for the xAxis categories from the series data
    const categories = data.map((item) => item.name)

    const options: EChartOption = {
        textStyle: {
            fontFamily: "GibsonCustom",
        },
        yAxis: {
            type: "category",

            data: categories,
            axisLabel: {
                inside: false,
                fontSize: 14,
                color: "#aaa",
            },
        },
        xAxis: {
            type: "value",
            axisLabel: {
                color: "#aaa",
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(255, 255, 255, 0.2)",
                },
            },
        },
        grid: {
            left: "0%",
            right: "6%",
            bottom: "0%",
            top: "3%",
            containLabel: true,
        },
        series: [
            {
                data: data,
                type: "bar",
                label: {
                    show: true,
                    fontSize: 18,
                    rotate: 0,
                    position: "right",
                    color: "white",
                    fontWeight: "bold",
                    distance: 10,
                    verticalAlign: "middle",
                    align: "left",
                    formatter: function (params: any) {
                        return params.value ? params.value.toLocaleString() : ""
                    },
                },
            },
        ],
    }

    return options
}

export default ProductTotalsBarTile
