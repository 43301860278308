import React, { useContext } from "react"
import styles from "./StackedAreaTile.module.css"
import baseStyles from "../Tile.module.css"
import { CountSummary, DeviceCount, Product } from "../Model/types"
import { EchartsReactWrapper } from "../../Components/EchartReactWrapper"
import { ProductColorsContext } from "../SimpleDashboard"
import dayjs from "dayjs"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
dayjs.extend(isSameOrBefore)

export interface StackedAreaTileProps {
    size?: { width: number; height: number }
    countSummary: CountSummary
}

const StackedAreaTile: React.FC<StackedAreaTileProps> = ({ size = { width: 1000, height: 1000 }, countSummary }) => {
    const layoutStyle = size.width > 300 ? styles.largeLayout : styles.smallLayout

    const productColors = useContext(ProductColorsContext)

    const options = getOptions(countSummary, productColors)

    return (
        <div className={styles.stackedAreaTile}>
            <div className={baseStyles.title} style={{ paddingBottom: "20px" }}>
                Connected Device History
            </div>
            <div style={{ flexGrow: 1 }}>
                <div className={styles.chart}>
                    <EchartsReactWrapper option={options as any} />
                </div>
            </div>
            <div className={styles.disclaimer}>*Chart shows weekly averages</div>
        </div>
    )
}

function getOptions(countSummary: CountSummary, colors: { [id: string]: string }): any {
    const series = getWeeklyAveragedSeries(countSummary.products, 7, colors)

    const legendData = countSummary.products.map((product) => {
        return product.name
    })

    const option = {
        textStyle: {
            fontFamily: "GibsonCustom",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
            formatter: function (params: any) {
                // Sort the params array based on the value in descending order
                params.sort((a: any, b: any) => b.value[1] - a.value[1])

                // Calculate the total value for the hovered timestamp
                const total = params.reduce((sum: number, item: any) => sum + item.value[1], 0)

                // Create the tooltip content
                let content = `<div style="margin-bottom: 5px; font-size: 18px; font-family:'GibsonCustom' ">Total: <b>${total?.toLocaleString()}</b></div>`
                params.forEach((item: any) => {
                    content += `<div style="font-size: 14px; font-family:'GibsonCustom' ">${item.marker} ${item.seriesName}: <b>${item.value[1]?.toLocaleString()}</b></div>`
                })

                return content
            },
        },
        legend: {
            data: legendData,
            itemGap: 20,
            selectedMode: "multiple",
            textStyle: {
                color: "#aaa",
                fontSize: "16px",
            },
            lineStyle: {
                width: 0,
            },
        },
        xAxis: {
            type: "time",
            axisLabel: {
                color: "#aaa",
            },
        },
        yAxis: {
            type: "value",
            splitLine: {
                lineStyle: {
                    color: "rgba(255, 255, 255, 0.2)",
                },
            },
            axisLabel: {
                color: "#aaa",
            },
        },
        grid: {
            left: "0%",
            right: "1%",
            bottom: "0%",
            top: "20%",
            containLabel: true,
        },
        series: series,
    }

    return option
}

// Helper to find the most recent and oldest timestamps across all products
const findDateRange = (products: Product[]) => {
    let minTimestamp = Infinity
    let maxTimestamp = -Infinity

    products.forEach((product) => {
        product.historicalCounts.forEach((count) => {
            if (count.timestamp < minTimestamp) minTimestamp = count.timestamp
            if (count.timestamp > maxTimestamp) maxTimestamp = count.timestamp
        })
    })

    return { minTimestamp, maxTimestamp }
}

const groupByIncrementAndAlign = (products: Product[], dayGap: number, minTimestamp: number, maxTimestamp: number) => {
    const groupedData: { [key: string]: { [productKey: string]: DeviceCount[] } } = {}

    // Iterate through each day from the maxTimestamp back in dayGap increments
    for (let current = dayjs(maxTimestamp); current.isAfter(minTimestamp); current = current.subtract(dayGap, "day")) {
        const dateKey = current.startOf("day").format("YYYY-MM-DD")

        products.forEach((product) => {
            const relevantCounts = product.historicalCounts.filter((count) => dayjs(count.timestamp).isSameOrBefore(current) && dayjs(count.timestamp).isAfter(current.subtract(dayGap, "day")))

            // If no counts within the range, use the closest available previous count
            if (!groupedData[dateKey]) groupedData[dateKey] = {}
            if (relevantCounts.length > 0) {
                groupedData[dateKey][product.key] = relevantCounts
            } else {
                // Handle gaps by taking the last known count if no data for this range
                const closestPreviousCount = product.historicalCounts.filter((count) => dayjs(count.timestamp).isBefore(current)).sort((a, b) => b.timestamp - a.timestamp)[0]

                // If there's no previous data at all, use a default fallback (e.g., connectedCount = 0)
                if (closestPreviousCount) {
                    groupedData[dateKey][product.key] = [closestPreviousCount]
                } else {
                    // No previous data exists, default to an empty count or set it to 0
                    groupedData[dateKey][product.key] = [
                        {
                            id: "fallback",
                            uuid: 0,
                            connectedCount: 0, // Ensure there's no NaN by setting a default value
                            totalCount: 0,
                            productKey: product.key,
                            timestamp: current.valueOf(),
                        },
                    ]
                }
            }
        })
    }

    return groupedData
}

// Helper to calculate average connectedCount for a group
const calculateAverage = (counts: DeviceCount[]) => {
    if (!counts || counts.length === 0) return 0 // Protect against empty arrays

    const total = counts.reduce((sum, count) => {
        const validCount = count.connectedCount ?? 0 // Ensure `connectedCount` is valid
        return sum + validCount
    }, 0)

    return Math.round(total / counts.length)
}

// Main function to generate weekly (or configurable) averaged series for ECharts
const getWeeklyAveragedSeries = (products: Product[], dayGap: number = 7, colors: { [id: string]: string }) => {
    // Step 1: Find the global min/max timestamps
    const { minTimestamp, maxTimestamp } = findDateRange(products)

    // Step 2: Group data by week (or other interval) and align it across all products
    const groupedData = groupByIncrementAndAlign(products, dayGap, minTimestamp, maxTimestamp)

    // Step 3: Map each product to an ECharts series
    return products.map((product) => {
        const data = Object.entries(groupedData).map(([date, productGroup]) => ({
            name: date,
            value: [
                new Date(date).getTime(), // Use the start of the group as the timestamp
                calculateAverage(productGroup[product.key] || []), // Calculate average for this product in the group
            ],
        }))

        return {
            name: product.name,
            type: "line",
            stack: "Total",
            data: data.sort((a, b) => a.value[0] - b.value[0]), // Ensure data is sorted by timestamp
            symbol: "none",
            emphasis: {
                focus: "series",
            },
            areaStyle: {
                opacity: 1,
            },
            lineStyle: {
                width: 0.5,
                color: "rgb(19 13 31)",
            },
            color: colors[product.key], // Use the color input for the product
        }
    })
}

export default StackedAreaTile
