import { useEffect, useRef, useState } from "react"
import { BrowserRouter as Router, Route, Routes, NavLink } from "react-router-dom"

import { Security, SecureRoute, LoginCallback } from "@okta/okta-react"
import "./App.css"
import Store from "./Model/Store"
import { OrgChartComponent } from "./OrgChart/OrgChart"
import ValueStreams from "./ValueStreams/ValueStreams"
import ResourceFinder from "./ResourceFinder/ResourceFinder"
import ServiceCatalog from "./Services/ServiceCatalog"
import Home from "./Home/Home"
import { ReactComponent as LogoutIcon } from "./Assets/logout.svg"
// import { ReactComponent as PersonSearchSvg } from "./Assets/personSearch.svg"
import "./themes.css"
import OktaAuth from "@okta/okta-auth-js"
import Metrics from "./Metrics/Metrics"
import { Provider } from "react-redux"
import reduxStore from "./Model/ReduxStore"
import oktaAuth from "./Model/oktaAuthSingleton"
import ProcessMap from "./ProcessMap/ProcessMap"
import { getMenuData } from "./Utils/getMenuData"
import LoadingStatus from "./Components/LoadingStatus/LoadingStatus"
import loaderStyles from "./Components/LoadingStatus/LoadingStatus.module.css"
import FoundryCalendar from "./Calendar/FoundryCalendar"
import UserResearch from "./UserResearch/UserResearch"
import SimplifiedValueStreams from "./ValueStreams/SimplifiedValueStreams"
import { Logout } from "@mui/icons-material"
import ProjectYarn from "./ProjectYarn/ProjectYarn"
import LeanCanvas from "./LeanCanvas/LeanCanvas"
import LeanCanvasWrapper from "./LeanCanvas/LeanCanvasWrapper"

export interface OktaUser {
    sub: string | undefined
    name: string | undefined
    locale: string | undefined
    email: string | undefined
    preferred_username: string | undefined
    given_name: string | undefined
    family_name: string | undefined
    zoneinfo: string | undefined
    updated_at: number | undefined
    email_verified: boolean | undefined
}

type AuthState = {
    isAuthenticated: boolean
    user: OktaUser | null
    isAuthenticating: boolean
}

function App() {
    const [authState, setAuthState] = useState<AuthState>({
        isAuthenticated: false,
        user: null,
        isAuthenticating: true,
    })
    const [needsSignInHelp, setNeedsSignInHelp] = useState(false)
    const [redirectUri, setRedirectUri] = useState<string | null>(null)

    let gotoProjectYarn = false
    if (window.location.pathname.includes("ProjectYarn")) {
        gotoProjectYarn = true
    }

    // I'm trying to preserve the original URL from after the login redirect
    // it is currently not working
    useEffect(() => {
        if (window.location.pathname.includes("ProjectYarn")) {
            gotoProjectYarn = true
        } else if (window.location.pathname === "/auth/login/callback") {
            oktaAuth.handleLoginRedirect().then(() => {
                checkAuthentication().then(() => {
                    const stateString = oktaAuth.authStateManager.getAuthState()?.state as string

                    if (stateString) {
                        try {
                            const { fromUri } = JSON.parse(stateString)
                            setRedirectUri(fromUri) // Set state instead of immediate redirect
                        } catch (error) {
                            console.error("Error parsing state parameter:", error)
                            setRedirectUri("/")
                        }
                    } else {
                        setRedirectUri("/")
                    }
                })
            })
        } else {
            checkAuthentication()
        }
    }, [])

    useEffect(() => {
        if (redirectUri) {
            window.location.replace(redirectUri)
        }
    }, [redirectUri])

    const checkAuthentication = async () => {
        const isAuthenticated = await oktaAuth.isAuthenticated()
        if (isAuthenticated !== authState.isAuthenticated) {
            let user = null
            if (isAuthenticated) {
                try {
                    user = (await oktaAuth.getUser()) as OktaUser
                } catch (err) {
                    // handle or log the error as needed
                    console.error(err)
                }
            }
            setAuthState({ isAuthenticated, user, isAuthenticating: false })
        } else {
            setAuthState({
                isAuthenticated: false,
                user: null,
                isAuthenticating: false,
            })
        }
    }

    const login = async () => {
        // Capture the current path
        const fromUri = window.location.pathname + window.location.search
        oktaAuth.signInWithRedirect({
            // Pass the captured path in the state parameter or use Okta's built-in options if available
            state: JSON.stringify({ fromUri }),
        })
    }

    const logout = async () => {
        oktaAuth.tokenManager.clear()
        await oktaAuth.signOut()
    }

    if (gotoProjectYarn) {
        return <ProjectYarn />
    } else if (authState.isAuthenticating) {
        return (
            <div className="App">
                <div className={loaderStyles.container}>
                    <span className={loaderStyles.loader}>Authenticating</span>
                </div>
            </div>
        )
    } else if (authState.isAuthenticated && authState.user) {
        return (
            <div>
                <SecureApp oktaUser={authState.user} onSignOut={logout} />
            </div>
        )
    } else {
        return (
            <div className="sign-in-container">
                <div className="sign-in-card">
                    <div style={{ textAlign: "center" }}>Foundry Home</div>
                    <button className="sign-in-button" onClick={login}>
                        Sign In With Okta
                    </button>
                    {needsSignInHelp ? (
                        <div className="sign-in-help">
                            <div style={{ fontWeight: "bold", fontSize: "18px" }}>How to sign in:</div>
                            <div>
                                If this is your first time signing in, you will need to visit{" "}
                                <a className="help-link" href={`https://sso.carrier.io`}>
                                    sso.carrier.io{" "}
                                </a>{" "}
                                sso.carrier.io to activate your Foundry Okta account.
                            </div>
                            <div>
                                If you are unable to sign in to sso.carrier.io, we will need to make sure your email address has been added to the Foundry Okta group. Please contact{" "}
                                <a className="help-link" href={`slack://user?team=T014J7H9F0D&id=U02FR76S538`}>
                                    Jason Qi{" "}
                                </a>{" "}
                                and ask him to add you.
                            </div>
                            <div>
                                If you are still unable to sign in, you can reach out to{" "}
                                <a className="help-link" href={`slack://user?team=T014J7H9F0D&id=U02HQUF02QP`}>
                                    Jason Smith{" "}
                                </a>{" "}
                                for further assistance.
                            </div>
                        </div>
                    ) : (
                        <div
                            className="sign-in-help-trigger"
                            onClick={() => {
                                setNeedsSignInHelp(true)
                            }}
                        >
                            Need help signing in?
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

interface SecureAppProps {
    oktaUser: OktaUser
    onSignOut: () => void
}

const SecureApp: React.FC<SecureAppProps> = ({ oktaUser, onSignOut }) => {
    const store = useRef<Store>()
    const [theme, setTheme] = useState("")
    const [dataHasLoaded, setDataHasLoaded] = useState(false)
    const [showBanner, setShowBanner] = useState(true)

    useEffect(() => {
        store.current = new Store()
        const loadData = async () => {
            await store.current?.loadData(oktaAuth)
            setDataHasLoaded(true)
        }
        loadData()

        // document.addEventListener("keydown", (event) => {
        //     console.log(`Key: ${event.key} with keycode ${event.code} has been pressed`)
        //     setTheme("theme" + event.key)
        // })
    }, [])

    const menuData = getMenuData(oktaUser)

    const content =
        dataHasLoaded && store.current ? (
            <Routes>
                <Route path="/value-streams/*" element={<SimplifiedValueStreams store={store.current} />}>
                    <Route path="value-stream/:valueStreamId" element={<div />} />
                    <Route path="value-stream/:valueStreamId/roles/:roleId" element={<div />} />
                    <Route path="value-stream/:valueStreamId/resources/:resourceId" element={<div />} />
                    <Route path="roles/:roleId" element={<div />} />
                    <Route path="resources/:resourceId" element={<div />} />
                    {/* <div></div> */}
                    <Route path="platforms/:platformId" element={<div />} />
                    <Route path="platforms/:platformId/value-streams/:vsId" element={<div />} />
                    <Route path="platforms/:platformId/roles/:roleId" element={<div />} />
                    <Route path="platforms/:platformId/value-streams/:vsId/roles/:roleId" element={<div />} />
                    <Route path="platforms/:platformId/resources/:resourceId" element={<div />} />
                    <Route path="platforms/:platformId/value-streams/:vsId/resources/:resourceId" element={<div />} />
                </Route>
                <Route path="/functional-structure" element={<OrgChartComponent store={store.current} data={store.current.getOrgD3Data()} title="Functional Structure" />} />
                <Route path="/reporting-structure" element={<OrgChartComponent store={store.current} data={store.current.getTeamD3Data()} title="Reporting Structure" />} />
                <Route path="/resource-finder/*" element={<ResourceFinder store={store.current} />}>
                    <Route path="resource/:resourceId" element={<div />} />
                </Route>
                <Route path="/service-catalog" element={<ServiceCatalog store={store.current} />} />
                <Route path="/metrics/*" element={<Metrics />}></Route>
                <Route path="/process" element={<ProcessMap />} />
                <Route path="/calendar" element={<FoundryCalendar />} />
                <Route path="/user-research/*" element={<UserResearch />}>
                    <Route path="project/:projectId" element={<div />} />
                </Route>
                <Route path="/" element={<Home />} />
            </Routes>
        ) : (
            <LoadingStatus />
        )

    const onBannerClose = () => {
        setShowBanner(false)
    }

    const banner = (
        <div className="constructionBanner">
            <div className="closeButton" onClick={onBannerClose}>
                x
            </div>
            Hi Foundry!
            <br />
            <br />
            We've decided to transition from the existing Foundry Home to a new version of Foundry Home that will be built directly on AirTable. <br />
            <br />
            We plan to make this transition official at the end of the year. The Foundry Home will be deprecated and replaced with a new link that we will share-out closer to year-end for everyone to bookmark. <br />
            <br />
            If you have any questions, concerns, or ideas for the new Foundry Home - please reach out to Lisa Vossler or Christian Tepley and we'll be happy to connect.
        </div>
    )

    const menu = menuData.map((menuItem) => {
        return (
            <NavLink
                key={menuItem.name}
                to={menuItem.path}
                className={({ isActive }) => (isActive ? "sidebar-button selected" : "sidebar-button")}
                end={false} // false for all except for the Home route
            >
                <div>{menuItem.icon}</div>
                <div className="sidebar-text">{menuItem.name}</div>
            </NavLink>
        )
    })

    return (
        <Provider store={reduxStore}>
            <Router>
                <div className={`App ${theme}`}>
                    <div className="sidebar">
                        <div>{menu}</div>
                        <div>
                            <div className="sidebar-button">
                                <div style={{ display: "flex" }}>
                                    <Logout className="sidebar-svg" />
                                </div>
                                <div className="sidebar-text" onClick={onSignOut}>
                                    Sign Out <br /> <div className="sidebar-okta-username">{oktaUser.name}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showBanner && banner}
                    <div className="content" id="mainContent">
                        {content}
                    </div>
                </div>
            </Router>
        </Provider>
    )
}

export default App
