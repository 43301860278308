import React, { CSSProperties, useContext } from "react"
import styles from "./HistoricalGrid.module.css"
import baseStyles from "../Tile.module.css"
import { CountSummary, Product } from "../Model/types"
import { EchartsReactWrapper } from "../../Components/EchartReactWrapper"
import { generateGradientColors } from "../utils"
import { EChartOption } from "echarts"
import { ProductColorsContext } from "../SimpleDashboard"
import HistoricalCountTile from "./HistoricalCountTile"

export interface HistoricalGridTileProps {
    size?: { width: number; height: number }
    countSummary: CountSummary
}

const HistoricalGridTile: React.FC<HistoricalGridTileProps> = ({ size = { width: 1000, height: 1000 }, countSummary }) => {
    const layoutStyle = size.width > 300 ? styles.largeLayout : styles.smallLayout

    const widthMultiplier = 2.2
    const verticalPadding = 50
    const horizontalPadding = 20
    const width = size.width
    const height = size.height
    const boxCount = countSummary.products.length
    const layout = generateGridLayout(width, height, boxCount, widthMultiplier, verticalPadding, horizontalPadding)

    const countCharts = countSummary.products.map((product, index) => {
        const boxStyle: CSSProperties = {
            width: `${layout[index].width}px`,
            height: `${layout[index].height}px`,
            top: `${layout[index].top}px`,
            left: `${layout[index].left}px`,
        }
        return (
            <div key={"pie-percent-" + product.key} className={styles.historicalTileContainer}>
                <HistoricalCountTile size={size} countSummary={countSummary} params={{ productKey: product.key }} />
            </div>
        )
    })

    return (
        <div className={styles.historicalGridTile}>
            <div className={baseStyles.title}>Connected Device History by Product</div>
            <div className={styles.allChartsContainer}>{countCharts}</div>
            <div className={styles.disclaimer}>*Charts show weekly averages</div>
        </div>
    )
}

interface BoxDimensions {
    width: number
    height: number
}

interface BoxPosition extends BoxDimensions {
    top: number
    left: number
}

function calculateBoxDimensions(
    containerWidth: number,
    containerHeight: number,
    numberOfBoxes: number,
    widthMultiplier: number, // This will be 2 for your specific aspect ratio
    verticalPadding: number, // Padding between rows
    horizontalPadding: number // Padding between columns
): BoxDimensions {
    let optimalBoxWidth = 0
    let optimalBoxHeight = 0
    let minUnusedSpace = Number.MAX_SAFE_INTEGER

    for (let boxesPerRow = 1; boxesPerRow <= numberOfBoxes; boxesPerRow++) {
        let rowsNeeded = Math.ceil(numberOfBoxes / boxesPerRow)
        let totalVerticalPadding = (rowsNeeded - 1) * verticalPadding
        let totalHorizontalPadding = (boxesPerRow - 1) * horizontalPadding
        let availableWidth = containerWidth - totalHorizontalPadding
        let availableHeight = containerHeight - totalVerticalPadding
        let boxWidth = availableWidth / boxesPerRow
        let boxHeight = boxWidth / widthMultiplier

        if (rowsNeeded * boxHeight <= availableHeight) {
            let unusedSpace = (availableHeight - rowsNeeded * boxHeight) * availableWidth
            if (unusedSpace < minUnusedSpace) {
                minUnusedSpace = unusedSpace
                optimalBoxWidth = boxWidth
                optimalBoxHeight = boxHeight
            }
        }
    }

    return {
        width: optimalBoxWidth,
        height: optimalBoxHeight,
    }
}

function generateGridLayout(containerWidth: number, containerHeight: number, numberOfBoxes: number, widthMultiplier: number, verticalPadding: number, horizontalPadding: number): BoxPosition[] {
    const { width, height } = calculateBoxDimensions(containerWidth, containerHeight, numberOfBoxes, widthMultiplier, verticalPadding, horizontalPadding)

    const grid: BoxPosition[] = []
    let topOffset = 0

    for (let i = 0; i < numberOfBoxes; i++) {
        const row = Math.floor(i / Math.ceil(containerWidth / (width + horizontalPadding)))
        const col = i % Math.ceil(containerWidth / (width + horizontalPadding))

        if (col === 0 && i !== 0) {
            topOffset += height + verticalPadding
        }

        grid.push({
            top: topOffset,
            left: col * (width + horizontalPadding),
            width: width,
            height: height,
        })
    }

    // Adjust heights and top positions to fill the container vertically
    const totalHeight = topOffset + height
    const heightRatio = (containerHeight - (grid.length / Math.ceil(containerWidth / (width + horizontalPadding)) - 1) * verticalPadding) / totalHeight

    let adjustedTopOffset = 0
    for (let i = 0; i < numberOfBoxes; i++) {
        if (i % Math.ceil(containerWidth / (width + horizontalPadding)) === 0 && i !== 0) {
            adjustedTopOffset += grid[i - 1].height + verticalPadding * heightRatio
        }

        grid[i].height *= heightRatio
        grid[i].top = adjustedTopOffset
    }

    // Center-align the last row
    const lastRowStartIndex = Math.floor(numberOfBoxes / Math.ceil(containerWidth / (width + horizontalPadding))) * Math.ceil(containerWidth / (width + horizontalPadding))
    const lastRowItemCount = numberOfBoxes % Math.ceil(containerWidth / (width + horizontalPadding)) || Math.ceil(containerWidth / (width + horizontalPadding))
    const lastRowWidth = lastRowItemCount * (width + horizontalPadding) - horizontalPadding
    const lastRowLeftOffset = (containerWidth - lastRowWidth) / 2

    for (let i = lastRowStartIndex; i < numberOfBoxes; i++) {
        grid[i].left += lastRowLeftOffset
    }

    return grid
}

export default HistoricalGridTile
