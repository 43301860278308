import React, { useState, useEffect, useContext } from "react"
import { useNavigate, useLocation, Routes, Route } from "react-router-dom"
import styles from "./SimpleDashboard.module.css"
import { CountSummary } from "./Model/types"
import TabMenu from "./TabMenu"
import GrandTotalTile from "./Tiles/GrandTotalTile"
import CirclesTile from "./Tiles/CirclePacking/CirclesTile"
import ProductTotalsBarTile from "./Tiles/ProductTotalsBarTile"
import CategoryPieTile from "./Tiles/CategoryPieTile"
import StackedAreaTile from "./Tiles/StackedAreaTile"
import HistoricalGridTile from "./Tiles/HistoricalGridTile"
import PercentConnectedTile from "./Tiles/PercentConnectedTile"
import FeatureAvailabilityTile from "./Tiles/FeatureAvailabilityTile"
import { getProductColors } from "./Utils/getProductColors"
import DeviceMap from "./Tiles/Map/DeviceMap"
import { DataStoreContext } from "./ProjectYarn"

export interface SimpleDashboardProps {
    // countSummary: CountSummary
}

interface MenuOption {
    name: string
    content: React.ReactElement
    path: string
}

export const ProductColorsContext = React.createContext<{ [id: string]: string }>({ basic: "white" })

const SimpleDashboard: React.FC<SimpleDashboardProps> = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dataStore = useContext(DataStoreContext)
    const countSummary = dataStore.countSummary

    // Define all the menu options along with their respective content and paths
    const menuOptions: MenuOption[] = [
        {
            name: "Overview",
            content: (
                <div className={styles.content}>
                    <GrandTotalTile countSummary={countSummary} />
                    <CirclesTile countSummary={countSummary} />
                    <ProductTotalsBarTile countSummary={countSummary} />
                </div>
            ),
            path: "/",
        },
        {
            name: "Platform Categories",
            content: (
                <div className={styles.content}>
                    <CategoryPieTile countSummary={countSummary} />
                </div>
            ),
            path: "/platform-categories",
        },
        {
            name: "Historical",
            content: (
                <div className={styles.content}>
                    <StackedAreaTile countSummary={countSummary} />
                    <HistoricalGridTile countSummary={countSummary} />
                </div>
            ),
            path: "/historical",
        },
        {
            name: "Percent Connected",
            content: (
                <div className={styles.content}>
                    <PercentConnectedTile countSummary={countSummary} />
                </div>
            ),
            path: "/percent-connected",
        },
        {
            name: "Features",
            content: (
                <div className={styles.content}>
                    <FeatureAvailabilityTile countSummary={countSummary} />
                </div>
            ),
            path: "/features",
        },
        {
            name: "Device Map",
            content: (
                <div className={styles.mapContent}>
                    <DeviceMap />
                </div>
            ),
            path: "/device-map",
        },
    ]

    // Extract the names array for the TabMenu component
    const namesArray = menuOptions.map((option) => option.name)

    // Update the selected content and tab based on the current route
    useEffect(() => {
        const currentTab = menuOptions.find((option) => option.path === location.pathname)
        if (currentTab) {
            setSelectedIndex(menuOptions.indexOf(currentTab))
        }
    }, [location.pathname])

    // State to track the selected tab index
    const [selectedIndex, setSelectedIndex] = useState(0)

    // Callback to update the content and navigate to the selected route
    const onMenuValueChanged = (value: number) => {
        setSelectedIndex(value)
        navigate(menuOptions[value].path)
    }

    return (
        <ProductColorsContext.Provider value={getProductColors(countSummary)}>
            <div className={styles.simpleDashboard}>
                <div className={styles.menu}>
                    <TabMenu options={namesArray} selectedIndex={selectedIndex} onValueChanged={onMenuValueChanged} />
                </div>

                {/* Routes to render the corresponding content */}
                <Routes>
                    {menuOptions.map((option) => (
                        <Route key={option.path} path={option.path} element={option.content} />
                    ))}

                    {/* Redirect to the Overview page if no path is matched */}
                    <Route path="*" element={<GrandTotalTile countSummary={countSummary} />} />
                </Routes>
            </div>
        </ProductColorsContext.Provider>
    )
}

export default SimpleDashboard
