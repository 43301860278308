import { CountSummary } from "../Model/types"
import { generateGradientColors } from "../utils"

export const getProductColors = (countSummary: CountSummary) => {
    // create product colors
    // const inputColors = ["#61C6A9", "#438CD3", "#5743CC", "#AA0086", "#DCB620"]
    const inputColors = ["#DCB620", "#C75828", "#AA0086", "#5743CC", "#1F81E1", "#11C1C1"]
    inputColors.reverse()
    const steps = countSummary.products.length
    const colors = generateGradientColors(inputColors, steps)
    const productColors: { [id: string]: string } = colors.reduce((acc: any, color, index) => {
        acc[countSummary.products[index].key] = color
        return acc
    }, {})
    return productColors
}
