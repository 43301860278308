import React, { useContext, useMemo, useState } from "react"
import Map, { Layer, Marker, NavigationControl, Source } from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import styles from "./DeviceMap.module.css"
import { DataStoreContext } from "../../ProjectYarn"
import { Device, Product } from "../../Model/types"
import cities from "cities.json"
import Pin from "./pin"
import { mapboxToken } from "./mapboxToken"
import { ProductColorsContext } from "../../SimpleDashboard"

interface City {
    name: string
    lat: string // Latitude as a string
    lng: string // Longitude as a string
    country: string
    admin1: string // Admin level 1 code
    admin2: string // Admin level 2 code
}

const DeviceMap: React.FC<{}> = () => {
    const dataStore = useContext(DataStoreContext)
    const products = dataStore.countSummary.products
    const productColors = useContext(ProductColorsContext)

    const [viewState, setViewState] = useState({
        longitude: -100,
        latitude: 40,
        zoom: 3.5,
    })

    const devices = useMemo(() => generateData(products), [products])

    // Create GeoJSON data from the devices array
    const geojsonData = useMemo(() => createGeoJsonFromDevices(devices, productColors), [devices, productColors])

    return (
        <div className={styles.mapContainer}>
            <Map {...viewState} onMove={(evt) => setViewState(evt.viewState)} style={{ width: "100%", height: "100%" }} mapStyle="mapbox://styles/jsmithcarrier/cm1jcjhof00bd01p62h8rgsk7" mapboxAccessToken={mapboxToken} projection={{ name: "globe" }}>
                <NavigationControl position="bottom-right" />

                {/* Render clusters for each product */}
                {geojsonData.map(({ productKey, geoJson, color }) => (
                    <Source
                        key={productKey}
                        id={`product-${productKey}`}
                        type="geojson"
                        data={geoJson}
                        cluster={true}
                        clusterMaxZoom={14} // Max zoom level to cluster points
                        clusterRadius={50} // Cluster radius in pixels
                    >
                        {/* Layer for clustered circles */}
                        <Layer
                            id={`clusters-${productKey}`}
                            type="circle"
                            source={`product-${productKey}`}
                            filter={["has", "point_count"]}
                            paint={{
                                "circle-color": color,
                                "circle-radius": ["step", ["get", "point_count"], 15, 10, 20, 50, 25],
                            }}
                        />

                        {/* Count labels for clusters */}
                        <Layer
                            id={`cluster-count-${productKey}`}
                            type="symbol"
                            source={`product-${productKey}`}
                            filter={["has", "point_count"]}
                            layout={{
                                "text-field": "{point_count_abbreviated}",
                                "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                                "text-size": 12,
                            }}
                        />

                        {/* Layer for unclustered points */}
                        <Layer
                            id={`unclustered-point-${productKey}`}
                            type="circle"
                            source={`product-${productKey}`}
                            filter={["!", ["has", "point_count"]]}
                            paint={{
                                "circle-color": color,
                                "circle-radius": 6,
                                "circle-stroke-width": 1,
                                "circle-stroke-color": "#fff",
                            }}
                        />
                    </Source>
                ))}
            </Map>
        </div>
    )
}

// Function to create GeoJSON from devices array and group by product
const createGeoJsonFromDevices = (devices: Device[], productColors: { [key: string]: string }) => {
    const geoJsonData = devices.reduce((acc, device) => {
        const { productKey, lat, lng, productName } = device

        // Ensure productKey is defined
        if (!productKey) {
            console.warn(`Device with ID ${device.id} has no productKey, skipping...`)
            return acc
        }

        // Initialize a GeoJSON collection for the product if it doesn't exist
        if (!acc[productKey]) {
            acc[productKey] = {
                productKey,
                color: productColors[productKey],
                geoJson: {
                    type: "FeatureCollection",
                    features: [],
                },
            }
        }

        // Add the device as a GeoJSON feature
        acc[productKey].geoJson.features.push({
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [lng, lat],
            },
            properties: {
                productKey,
                productName,
            },
        })

        return acc
    }, {} as { [key: string]: { productKey: string; color: string; geoJson: any } })

    // Convert the object into an array for easier mapping in the component
    return Object.values(geoJsonData)
}

const generateData = (products: Product[]): Device[] => {
    const devices: Device[] = []
    const typedCities: City[] = cities as City[]

    for (let product of products) {
        for (let i = 0; i < 10; i++) {
            const city = getRandomItem<City>(typedCities)
            const device: Device = {
                id: product.name + "device-" + i,
                name: city.name,
                productName: product.name,
                productKey: product.key,
                lat: parseFloat(city.lat),
                lng: parseFloat(city.lng),
            }

            devices.push(device)
        }
    }

    return devices
}

export const getRandomItem = <T extends unknown>(array: T[]): T => {
    const randomIndex = Math.floor(Math.random() * array.length)
    return array[randomIndex]
}

export default DeviceMap
