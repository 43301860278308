import React from "react"
import { Tabs, Tab } from "@mui/material"
import logo from "../Assets/carrierLogo.svg"
import styles from "./TabMenu.module.css"

export interface TabMenuProps {
    options: string[]
    onValueChanged: (value: number) => void
    selectedIndex: number // New prop to sync with the selected tab
}

const TabMenu: React.FC<TabMenuProps> = ({ options, onValueChanged, selectedIndex }) => {
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        onValueChanged(newValue) // Call the callback with the new value
    }

    return (
        <div className={styles.tabMenu}>
            <div className={styles.tabsContainer}>
                <Tabs
                    value={selectedIndex}
                    onChange={handleChange}
                    aria-label="customized tabs example"
                    sx={{
                        ".MuiTabs-indicator": {
                            backgroundColor: "white", // Indicator color
                        },
                        ".MuiTab-root": {
                            color: "#AAA", // Default color for all tabs
                            font: "inherit",
                            textTransform: "none", // Disable uppercase text
                            fontWeight: "bold",
                            "&.Mui-selected": {
                                color: "white", // Selected tab color
                            },
                            "&:hover": {
                                color: "black", // Hover color for all tabs
                            },
                        },
                    }}
                >
                    {options.map((option, index) => (
                        <Tab key={index} label={option} />
                    ))}
                </Tabs>
            </div>
            <img src={logo} className={styles.logo} alt="Carrier" />
        </div>
    )
}

export default TabMenu
